@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,300');

html,
body {
  height: 100%;
}

body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-size: 14px;
  margin: 0;
  padding-left: 24px;
  padding-right: 24px;
}

fieldset {
  margin: 8px auto;
}

legend {
  font-weight: 600;
}

.control-group {
  display: flex;
  flex-direction: row;
  gap: 16px;

  .control {
    display: block;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    padding: 4px 8px;
  }
  label {
    display: inline-block;
    white-space: nowrap;
  }
  input {
    width: 100%;
  }
}

#Search {
  background-color: teal;
  border: none;
  color: white;
  height: 48px;
  text-transform: uppercase;
  width: 100%;
}

.align-top {
  vertical-align: top;
}

.element-container {
  border-radius: 5px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
  padding: 24px;
}
